import { createGetKcContext } from "keycloakify/login";

export type KcContextExtension =
  // WARNING: It's important to keep in sync the extraThemeProperties declared in the package.json and this type definition.
  | { pageId: "login.ftl" }
  | {
      pageId: "email-code-form.ftl";
      forgotEmail?: boolean;
      attemptedUsername?: string;
    }
  | { pageId: "forgot-email-form.ftl" }
  | { pageId: "login-reset-password.ftl" }
  | { pageId: "login-update-password.ftl" }
  | { pageId: "login-page-expired.ftl" }
  | { pageId: "login-username.ftl" }
  | { pageId: "login-idp-link-email.ftl" }
  | {
      pageId: "info.ftl";
      properties: {
        environment: any;
        urlDev: string;
        urlStg: string;
        urlProd: string;
      };
    }
  // NOTE: register.ftl is deprecated in favor of register-user-profile.ftl
  // but let's say we use it anyway and have this plugin enabled: https://github.com/micedre/keycloak-mail-whitelisting
  // keycloak-mail-whitelisting define the non standard ftl global authorizedMailDomains, we declare it here.
  | {
      pageId: "register.ftl";
      login: {};
      register: {
        context: string;
        formData: { email: string };
      };
      defaultStep: 1 | 2 | 3 | 4;
      realm: {
        displayName: string;
        registrationAllowed: boolean;
        displayNameHtml: string;
        resetPasswordAllowed: boolean;
        resetCredentialsActionTokenLifespanMinutes: string; // number represented as string ej. "5"
        password: boolean;
        actionTokenGeneratedByUserLifespanMinutes: string; // number represented as string ej. "5"
        internationalizationEnabled: boolean;
        idpVerifyAccountLinkActionTokenLifespanMinutes: string; // number represented as string ej. "5"
        verifyEmailActionTokenLifespanMinutes: string; // number represented as string ej. "5"
        loginWithEmailAllowed: boolean;
        duplicateEmailsAllowed: boolean;
        editUsernameAllowed: boolean;
        name: string;
        rememberMe: boolean;
        registrationEmailAsUsername: boolean;
        identityFederationEnabled: boolean;
      };
      url: {
        oauthAction: string;
        resourcesPath: string;
        loginAction: string;
        loginUrl: string;
        resourcesCommonPath: string;
        firstBrokerLoginUrl: string;
        registrationUrl: string;
        logoutConfirmAction: string;
        registrationAction: string;
        loginResetCredentialsUrl: string;
        resourcesUrl: string;
        loginRestartFlowUrl: string;
        oauth2DeviceVerificationAction: string;
      };
    };

//NOTE: In most of the cases you do not need to overload the KcContext, you can
// just call createGetKcContext(...) without type arguments.
// You want to overload the KcContext only if:
// - You have custom plugins that add some values to the context (like https://github.com/micedre/keycloak-mail-whitelisting that adds authorizedMailDomains)
// - You want to add support for extra pages that are not yey featured by default, see: https://docs.keycloakify.dev/contributing#adding-support-for-a-new-page
export const { getKcContext } = createGetKcContext<KcContextExtension>({
  mockData: [
    {
      pageId: "login.ftl",
      locale: {
        currentLanguageTag: "es",
      },
    },
    {
      pageId: "info.ftl",
      locale: {
        currentLanguageTag: "es",
      },
    },
    {
      pageId: "error.ftl",
      locale: {
        currentLanguageTag: "es",
      },
    },
    {
      pageId: "email-code-form.ftl",
      locale: {
        currentLanguageTag: "es",
      },
    },
    {
      pageId: "terms.ftl",
      locale: {
        currentLanguageTag: "es",
      },
    },
    {
      pageId: "login-password.ftl",
      locale: {
        currentLanguageTag: "es",
      },
    },
    {
      pageId: "login-username.ftl",
      locale: {
        currentLanguageTag: "es",
      },
      realm: { rememberMe: true },
    },
    {
      pageId: "login-idp-link-email.ftl",
      locale: {
        currentLanguageTag: "es",
      },
    },
    {
      //NOTE: You will either use register.ftl (legacy) or register-user-profile.ftl, not both
      pageId: "register-user-profile.ftl",
      locale: {
        currentLanguageTag: "es",
      },
      profile: {
        attributes: [
          {
            validators: {
              pattern: {
                pattern: "^[a-zA-Z0-9]+$",
                "ignore.empty.value": true,
                // eslint-disable-next-line no-template-curly-in-string
                "error-message": "${alphanumericalCharsOnly}",
              },
            },
            //NOTE: To override the default mock value
            value: undefined,
            name: "username",
          },
          {
            validators: {
              options: {
                options: [
                  "male",
                  "female",
                  "non-binary",
                  "transgender",
                  "intersex",
                  "non_communicated",
                ],
              },
            },
            // eslint-disable-next-line no-template-curly-in-string
            displayName: "${gender}",
            annotations: {},
            required: true,
            groupAnnotations: {},
            readOnly: false,
            name: "gender",
          },
        ],
      },
    },
    {
      pageId: "register.ftl",
      url: {
        loginAction:
          "http://localhost:8080/auth/realms/guemes/login-actions/authenticate?session_code=UCy3bslM50wCyF-uH4WY2AgduZcPhawhsTP4yFqP05Y&execution=e7f3d6cf-f401-4c20-aec6-d8bc8d9be964&client_id=guemes-web&tab_id=BDALGYNmQHI",
        loginUrl:
          "http://localhost:8080/auth/realms/guemes/login-actions/authenticate?client_id=guemes-web&tab_id=BDALGYNmQHI",
        registrationAction:
          "http://localhost:8080/auth/realms/guemes/login-actions/authenticate?session_code=UCy3bslM50wCyF-uH4WY2AgduZcPhawhsTP4yFqP05Y&execution=e7f3d6cf-f401-4c20-aec6-d8bc8d9be964&client_id=guemes-web&tab_id=BDALGYNmQHI",
        loginRestartFlowUrl:
          "http://localhost:8080/auth/realms/guemes/login-actions/restart?client_id=guemes-web&tab_id=BDALGYNmQHI",
      },
      client: {
        clientId: "guemes-web",
        description: "",
        name: "",
      },
      locale: {
        currentLanguageTag: "es",
      },
      realm: {
        displayName: "Sanatorio Güemes",
        registrationAllowed: true,
        displayNameHtml: "Sanatorio Güemes",
        resetPasswordAllowed: true,
        resetCredentialsActionTokenLifespanMinutes: "5",
        password: true,
        actionTokenGeneratedByUserLifespanMinutes: "5",
        internationalizationEnabled: true,
        idpVerifyAccountLinkActionTokenLifespanMinutes: "5",
        verifyEmailActionTokenLifespanMinutes: "5",
        loginWithEmailAllowed: true,
        duplicateEmailsAllowed: false,
        editUsernameAllowed: false,
        name: "guemes",
        rememberMe: true,
        registrationEmailAsUsername: true,
        identityFederationEnabled: true,
      },
      login: {},
      register: {
        context: "REGISTRATION_PROFILE",
        formData: {},
      },
      social: {
        displayInfo: true,
        providers: [
          {
            displayName: "Google",
            providerId: "google",
            loginUrl:
              "/auth/realms/guemes/broker/google/login?client_id=guemes-web&tab_id=3UpGixj_2Vo&session_code=y5sm6IDIfIHZ_I6POm4Pb1nxhlIOhm2bX3weyQ5THxs",
            alias: "google",
          },
          {
            displayName: "Facebook",
            providerId: "facebook",
            loginUrl:
              "/auth/realms/guemes/broker/facebook/login?client_id=guemes-web&tab_id=3UpGixj_2Vo&session_code=y5sm6IDIfIHZ_I6POm4Pb1nxhlIOhm2bX3weyQ5THxs",
            alias: "facebook",
          },
          {
            displayName: "Apple",
            providerId: "apple",
            loginUrl:
              "/auth/realms/guemes/broker/apple/login?client_id=guemes-web&tab_id=3UpGixj_2Vo&session_code=y5sm6IDIfIHZ_I6POm4Pb1nxhlIOhm2bX3weyQ5THxs",
            alias: "apple",
          },
        ],
      },
      // Simulate we got an error with the email field
      messagesPerField: {
        printIfExists: <T>(fieldName: string, className: T) => {
          return fieldName === "email" ? className : undefined;
        },
        existsError: (fieldName: string) => fieldName === "email",
        get: (fieldName: string) => `Fake error for ${fieldName}`,
        exists: (fieldName: string) => fieldName === "email",
      },
    },
  ],
});

export const { kcContext } = getKcContext({
  // Uncomment to test the login page for development.
  //mockPageId: "login.ftl",
});

export type KcContext = NonNullable<
  ReturnType<typeof getKcContext>["kcContext"]
>;
